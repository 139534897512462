
.contBtn{
    background-color: #fff;
    padding: 10px 25px !important;
    border-radius: 20px;
    font-family: "Ink Free";
    font-size: 20px;
    cursor: pointer;
    color: #000 !important;
    font-weight: 700 !important;
}
.play_header_main{
    /* background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90)); */

}

.inner_whitelist{
    width: 40%;
    /* background-color: #000; */
    padding: 20px;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.5);
}

.inner_whitelist label{
    font-size: 16px;
    padding: 0px;
    margin: 0px !important;
}
.Whit_list_div_main{
    display: flex;
    margin-top: 3rem;
}
.inner_whitelist button{
    margin-top: 1rem;

    text-transform: capitalize !important;
    color: #fff;  
    background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));
    border: none;
    padding: 10px;
    border-radius: 5px;
}

@media screen and (max-width:600px) {
    .inner_whitelist{
        width: 100%;
        /* background-color: #000; */
        padding: 20px;
        border-radius: 15px;
        background: rgba(0, 0, 0, 0.5);
    }
    .Refferal_input {
        background-color: transparent;
        border: 1px solid #fff;
        width: 100%;
        height: 2rem;
        color: #fff;
        font-size: 1.1rem;
        margin-left: 1rem;
    }
}