.mint_main {
    background-image: url('../Assets/Surgical.jpg'), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)) !important;
    /* width: 100%; */
    background-blend-mode: overlay;
    background-size: cover;
    height: auto ;
    /* width: 100vw !important; */
    padding: 0;
    padding-top: 0px;
    padding-bottom: 250px;
}
/* .main_layout{
    background-color: ;
} */

.card_heading_span {
    font-weight: 700;
    font-size: 25px !important;
    text-transform: capitalize !important;
    color: #fff;
}
.inner_reffer_div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.refferal_card {
    width: 350px;
    padding: 10px 20px;
    /* margin: 0rem auto auto; */
    background-color: rgba(1, 1, 26, 0.466);
    border-radius: 1rem;
    border-top: 3px;
    border-style: solid;
    border-color: rgb(150, 38, 29) !important;
    box-shadow: 0 0 10px #9ecaed;
    height: auto;
    text-align: center;

}

.Refferal_input {
    background-color: transparent;
    border: 1px solid #fff;
    width: 250px;
    height: 2rem;
    color: #fff;
    font-size: 1.1rem;
    margin-left: 1rem;
}

.copy_refferal_address {
    /* display: flex;
    align-items: center; */
}

.copy_btn {

    padding: 5px 19px !important;
    border: none !important;
    outline: none !important;
    ;
    font-size: 22px !important;
    width: 250px;
    color: white;

}
.clr {
    background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));
}
.refferal_copy{
    display: flex;
    align-items: center;
    margin-top: 3rem;
}
.refferal_copy h6{
    font-weight: 700;
    font-size: 25px !important;
    text-transform: capitalize !important;
    color: #fff !important;   
    /* margin-left: 1rem; */
    margin-top: 1rem;
}
.refferal_copy button{
    margin-top: 1rem;
    /* padding: 5px; */
    margin-left: 1rem;
    text-transform: capitalize !important;
    color: #fff;  
    background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));
    border: none;
    height: 2rem;
    width: 4rem;

}
.play_header h1{
    color: #fff;  
    padding-top: 4rem;

}
.contBtn{
    background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));
    text-transform: capitalize !important;
    color: #fff !important;
    font-weight: 700;

    text-transform: capitalize !important;
    color: #fff;

}
.contBtn:hover{
    background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));

}

@media screen and (max-width:600px) {
    .refferal_copy h6{
        font-weight: 700;
        font-size: 18px !important;
        text-transform: capitalize !important;
        color: #fff !important;   
        /* margin-left: 1rem; */
        margin-top: 1rem;
    }
    .refferal_copy{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3rem;
    }
}