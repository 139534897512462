.nav_bg{
    background-color: #96261D;
    /* padding: 25px; */
    width: 100%;
    height: 5rem;
    
}
.navbar-brand img{
    width: 60px;
    height: 60px,
}
.activedApps{
    color: #fff !important;
    font-size: 2rem !important;
    font-weight: 700;
}

.logout_btn{
  
    text-transform: capitalize !important;
    color: #fff;  
    background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));
    border: none;
    height: 2.5rem;
    width: 5rem;
    color: #fff !important;
    font-size: 1rem !important;
    font-weight: 700;
    border: 1px solid #fff;
}
.logout_btn:hover{
    background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));

}
@media (min-width:992px) {
    .respn{
        display: none;
    }
    
}