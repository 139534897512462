
.User_Authentication{
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	font-family: 'Jost', sans-serif;
	/* background: linear-gradient(to bottom, #0f0c29, #302b63, #24243e); */
	background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));
}
.main{
	width: 350px;
	height: 520px;
	background: red;
	overflow: hidden;
	background: url("https://doc-08-2c-docs.googleusercontent.com/docs/securesc/68c90smiglihng9534mvqmq1946dmis5/fo0picsp1nhiucmc0l25s29respgpr4j/1631524275000/03522360960922298374/03522360960922298374/1Sx0jhdpEpnNIydS4rnN4kHSJtU1EyWka?e=view&authuser=0&nonce=gcrocepgbb17m&user=03522360960922298374&hash=tfhgbs86ka6divo3llbvp93mg4csvb38") no-repeat center/ cover;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
}
#chk{
	display: none;
}
.signup{
	position: relative;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	margin-top: -1rem;
	
	
}
label{
	color: #fff;
	font-size: 2.3em;
	justify-content: center;
	display: flex;
	margin: 60px;
	font-weight: bold;
	cursor: pointer;
	transition: .5s ease-in-out;
}
input{
	width: 100%;
	height: 40px;
	background: #e0dede;
	justify-content: center;
	display: flex;
	/* margin: 20px auto; */
	padding: 10px;
	border: none;
	outline: none;
	border-radius: 5px;
	margin-top: 1rem !important;
	
}
.btn_signup button{
	width: 60%;
	height: 40px;
	margin: 10px auto;
	justify-content: center;
	display: block;
	color: #fff;
	background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));

	font-size: 1em;
	font-weight: bold;
	margin-top: 0px;
	
	outline: none;
	border: 1px solid #fff;
	border-radius: 5px;
	transition: .2s ease-in;
	cursor: pointer;
}
button:hover{
	background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));

}
.login{
	height: 460px;
	background: #eee;
	border-radius: 60% / 10%;
	transform: translateY(-180px);
	transition: .8s ease-in-out;
	display: flex;
	justify-content: center;
	margin-top: 6rem;

}
.login label{
	color: rgb(150, 38, 29);
	transform: scale(.6);
}

#chk:checked ~ .login{
	transform: translateY(-500px);
}
#chk:checked ~ .login label{
	transform: scale(1);	
}
#chk:checked ~ .signup label{
	transform: scale(.6);
}

.btn_signup{
	display: flex;
	justify-content: center;
	margin-top: 1rem;
}
.form_div{
	/* margin-top: -1rem; */
}

.forgetpassword{
	left: auto;
	color: red;
	font-size: 14px;
	cursor: pointer;
	
}
.input_foregt{
	width: 100%;
	height: 40px;
	background: #e0dede;
	justify-content: center;
	display: flex;
	/* margin: 20px auto; */
	padding: 10px;
	border: none;
	outline: none;
	border-radius: 5px;
	margin-top: 1rem !important;
}
.submit_button{
	width: 60%;
	height: 40px;
	margin: 10px auto;
	justify-content: center;
	display: block;
	color: #fff;
	background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));
	font-size: 1em;
	font-weight: bold;
	margin-top: 0px;

	
	outline: none;
	border: 1px solid #fff;
	border-radius: 5px;
	transition: .2s ease-in;
	cursor: pointer;
}
.submit_button:hover{
	background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));

}

.main1{
	width: 400px;
	height: 520px;
	background: red;
	overflow: hidden;
	background: url("https://doc-08-2c-docs.googleusercontent.com/docs/securesc/68c90smiglihng9534mvqmq1946dmis5/fo0picsp1nhiucmc0l25s29respgpr4j/1631524275000/03522360960922298374/03522360960922298374/1Sx0jhdpEpnNIydS4rnN4kHSJtU1EyWka?e=view&authuser=0&nonce=gcrocepgbb17m&user=03522360960922298374&hash=tfhgbs86ka6divo3llbvp93mg4csvb38") no-repeat center/ cover;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
}

.ant-input-affix-wrapper {
    position: relative;
    display: inline-flex;
    width: 100%;
    min-width: 100%;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;

    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
    height: 3rem !important;
	background: #e0dede;
	
}
.ant-input-affix-wrapper >input.ant-input {
    padding: 0;
    font-size: inherit;
    border: none;
    border-radius: 0;
    outline: none;
    height: 1rem !important;
	margin-top: 0.7rem !important;
	font-size: 20px;
	background: #e0dede;
}
.icons{
	color: #fff;
}



.inner_connectWallet button{
	width: 100%;
	height: 40px;
	margin: 10px auto;
	justify-content: center;
	display: block;
	color: #fff;
	background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));
	font-size: 1em;
	font-weight: bold;
	margin-top: 0px;

	
	outline: none;
	border: 1px solid #fff;
	border-radius: 5px;
	transition: .2s ease-in;
	cursor: pointer;
}
.connectWallet{
	position: absolute;
	top: 30px;
	right: 40px;
}

.main5{
	width:30rem !important;
	height: 520px;
	background: red;
	overflow: hidden;
	background: url("https://doc-08-2c-docs.googleusercontent.com/docs/securesc/68c90smiglihng9534mvqmq1946dmis5/fo0picsp1nhiucmc0l25s29respgpr4j/1631524275000/03522360960922298374/03522360960922298374/1Sx0jhdpEpnNIydS4rnN4kHSJtU1EyWka?e=view&authuser=0&nonce=gcrocepgbb17m&user=03522360960922298374&hash=tfhgbs86ka6divo3llbvp93mg4csvb38") no-repeat center/ cover;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
}

@media screen and (max-width:600px) {
	.main5{
		width:auto !important;
		height: 520px;
		background: red;
		overflow: hidden;
		background: url("https://doc-08-2c-docs.googleusercontent.com/docs/securesc/68c90smiglihng9534mvqmq1946dmis5/fo0picsp1nhiucmc0l25s29respgpr4j/1631524275000/03522360960922298374/03522360960922298374/1Sx0jhdpEpnNIydS4rnN4kHSJtU1EyWka?e=view&authuser=0&nonce=gcrocepgbb17m&user=03522360960922298374&hash=tfhgbs86ka6divo3llbvp93mg4csvb38") no-repeat center/ cover;
		border-radius: 10px;
		box-shadow: 5px 20px 50px #000;
	}
}


.btn_signup1 button{
	/* width: 60%;
	height: 40px; */
	margin: 50px auto;
	margin-top: 1rem !important;
	justify-content: center;
	display: block;
	color: #fff;
	background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));
padding: 5px 15px 5px 15px;
	font-size: 1em;
	font-weight: bold;
	margin-top: 0px;
	
	outline: none;
	border: 1px solid #fff;
	border-radius: 5px;
	transition: .2s ease-in;
	cursor: pointer;
}
.wallet_auth label{
	margin: 0px !important;
	margin-top: 60px !important;
}
.wallet_auth input{
	margin-top: 60px !important;
	width: 90% !important;

}
.wallet_auth .btn_signup button{
	width: 50%;
}